import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import cn from 'classnames';
import Script from 'next/script';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CountryAccordion, CustomizedAccordion } from '../../components';
import { CURRENCY } from '../../config';
import Logo from '../../public/logo.svg';
import styles from './MobileHeader.module.scss';
import logoStyles from '../../components/assets/Logo.module.scss';
import { changeCurrency } from '../../components/utils/changeCurrency';
import { HAS_ETG } from '../../config/hasETG';
import PersonIcon from '@mui/icons-material/Person';

export const MobileHeader = ({
  transparentHeader,
  menuItems,
  sessionState,
  toggleLoginDialog,
  loginDialogState,
  session,
  ...props
}) => {
  const [showForum, setShowForum] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const userStore = useSelector((state) => state.user.value);
  const headerRef = useRef(null);

  useEffect(() => {
    if (
      userStore?.country === 'SE' ||
      userStore?.country === 'DK' ||
      userStore?.country === 'NO'
    ) {
      setShowForum(true);
    } else {
      setShowForum(false);
    }
  }, [userStore?.country]);

  useEffect(() => {
    let cookieState = JSON.parse(localStorage.getItem('bcCookieConsentShowed'));
    if (cookieState) {
      setCookieAccepted(cookieState);
    } else {
      window.addEventListener('storage', () => {
        cookieState = JSON.parse(localStorage.getItem('bcCookieConsentShowed'));
        if (cookieState) {
          setCookieAccepted(cookieState);
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setMenuState(false);
        setSearchState(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [headerRef]);

  return (
    <>
      <div
        ref={headerRef}
        className={cn('w-full px-2')}
        {...props}
      >
        <div className={cn('w-full flex justify-between items-center')}>
          <nav className="m-2">
            <ul className="flex h-full">
              <li className="my-auto">
                <a href="/">
                  <Logo
                    className={cn({
                      [logoStyles.logoMobileBlack]: !transparentHeader,
                      [logoStyles.logoMobile]: transparentHeader,
                    })}
                  />
                </a>
              </li>
            </ul>
          </nav>
          <div className="m-2">
            <SearchRoundedIcon
              className="cursor-pointer"
              fontSize={'medium'}
              onClick={() => {
                setSearchState(!searchState);
              }}
            />
            {!sessionState?.userid && (
              <PersonOutlineRoundedIcon
                className="lg:mx-5 sm:ml-6 cursor-pointer"
                fontSize={'medium'}
                sx={{
                  color: transparentHeader ? '#ffffff' : '#202020',
                  fontSize: '28px',
                }}
                onClick={() => {
                  if (sessionState && sessionState.userid) {
                    document.location = '/profile';
                    return;
                  }
                  toggleLoginDialog(!loginDialogState);
                }}
              />
            )}
            {sessionState?.userid && (
              <PersonIcon
                className="lg:mx-5 sm:ml-6 cursor-pointer"
                fontSize={'medium'}
                sx={{
                  color: transparentHeader ? '#ffffff' : '#202020',
                  fontSize: '28px',
                }}
                onClick={() => {
                  if (sessionState && sessionState.userid) {
                    document.location = '/profile';
                    return;
                  }
                  toggleLoginDialog(!loginDialogState);
                }}
              />
            )}
            {!menuState && (
              <MenuRoundedIcon
                fontSize={'medium'}
                className="lg:mx-5 sm:ml-6 cursor-pointer"
                onClick={() => setMenuState(true)}
              />
            )}
            {menuState && (
              <CloseRoundedIcon
                fontSize={'medium'}
                className="lg:mx-5 sm:ml-6 cursor-pointer"
                onClick={() => setMenuState(false)}
              />
            )}
          </div>
        </div>

        <div
          className={cn('flex flex-col px-3 font-libre', {
            hidden: !searchState,
            visible: searchState,
            [styles.searchContainer]: searchState,
          })}
        >
          {cookieAccepted && (
            <Script
              src="https://cse.google.com/cse.js?cx=e8c19a0f685acc0d8"
              strategy="lazyOnload"
            />
          )}
          <span className="w-full text-xs">Search on this website</span>
          <div className="gcse-search"></div>
        </div>
        {menuState && (
          <div
            className={cn(
              'flex flex-col font-libre, bg-white',
              styles.menuContainer
            )}
          >
            <div className={cn(styles.accordionTitle, 'w-full px-5')}>
              <a href="/club">The Club</a>
            </div>
            {sessionState && HAS_ETG[sessionState?.country]?.hasEtg && (
              <div className={cn(styles.accordionTitle, 'w-full px-5 py-1')}>
                <a
                  onClick={() => {
                    if (sessionState.country) {
                      let country = sessionState.country.toLowerCase();
                      if (country === 'gb') {
                        country = 'uk';
                      }
                      window.open(
                        `https://${country}.flights.businessclass.com/rf/order-login`,
                        '_blank'
                      );
                    }
                  }}
                >
                  My Bookings
                </a>
              </div>
            )}
            <div className={cn(styles.accordionTitle, 'w-full px-5')}>
              <CustomizedAccordion
                label="Airlines"
                items={menuItems.airlines}
              />
            </div>
            <div className={cn(styles.accordionTitle, 'w-full px-5')}>
              <CustomizedAccordion label="Flights" items={menuItems.flights} />
            </div>
            <div className={cn(styles.accordionTitle, 'w-full px-5')}>
              <CustomizedAccordion label="Hotels" items={menuItems.hotels} />
            </div>
            <div className={cn(styles.accordionTitle, 'w-full px-5')}>
              <CustomizedAccordion label="More" items={menuItems.more} />
            </div>
            {showForum && (
              <div className={cn(styles.accordionTitle, 'w-full px-5')}>
                <a href="/forum">Forum</a>
              </div>
            )}
            <div className={cn(styles.accordionTitle, 'w-full px-5')}>
              <CustomizedAccordion
                items={Object.keys(CURRENCY).map((currency) => {
                  return {
                    label: currency,
                    action: () => changeCurrency(currency, session),
                  };
                })}
                label={sessionState?.currency}
                columnsNumber={3}
              />
            </div>
            <div className={cn(styles.accordionTitle, 'w-full px-5')}>
              <CountryAccordion
                currentCountry={sessionState?.country}
                session={session}
                columnsNumber={2}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

