import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { httpGet, httpPut } from '../../../utils/httpClient';
import { Popper, Paper, Button, Typography, Box, CircularProgress } from '@mui/material';
import { FlightSearchAutocomplete } from '../../../components/FlightSearch/FlightSearchAutocomplete/FlightSearchAutocomplete';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@material-ui/core';

const UserAirportDropdown = ({ anchorEl, onClose }) => {
  const userStore = useSelector((state) => state.user.value);
//   const userCity = useSelector((state) => state.userStore.city); // Adjust selector based on your store
  const [nearestAirport, setNearestAirport] = useState(null);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (!userStore?.detectLocation) return;

    if (localStorage.getItem('BC_preferredAirport') || userStore?.userid) {
      setIsShown(false);
    } else {
      setIsShown(true);

      setLoading(true);
      httpGet(`/api/airport/autocomplete?search=${userStore?.detectLocation}`)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.airports.length > 0) {
            // Select the first airport as the nearest for simplicity
            setNearestAirport(data.airports[0]);
          } else {
            setShowAutocomplete(true);
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [userStore?.detectLocation, userStore?.userid]);

  const savePreferredAirport = (airport) => {
    document.cookie = `BC_preferredAirport=${JSON.stringify(airport)} max-age=31536000;path=/;domain=.businessclass.com`;
    localStorage.setItem('BC_preferredAirport', JSON.stringify(airport));
    if (userStore?.userid) {
      httpPut('/api/user/profile', {
        userAirports: [
          {
            code: airport?.Iata || airport?.code,
            name: airport?.ShortName || airport?.name,
            isCity:
              !airport?.ParentAirport || airport?.isCity,
          },
        ],
      });
    }
    onClose();
  };

  return (
    <Popper open={!!anchorEl && isShown} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1300 }}>
      <Paper style={{ padding: '16px', paddingTop: '24px', minWidth: '300px' }}>
        <IconButton
            color="inherit"
            onClick={() => onClose(true)}
            aria-label="close"
            sx={{ position: 'absolute', right: 0, top: 0 }}
        >
            <CloseIcon />
        </IconButton>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" padding="16px">
            <CircularProgress size={24} />
          </Box>
        ) : (
          <>
            {!showAutocomplete ? (
              <>
                <Typography variant="body1" gutterBottom className='pr-8'>
                  Is this your closest preferred airport?
                  <Tooltip title="We will use this airport as your origin and try to show you more personalized flight deals" placement="right">
                    <InfoIcon sx={{color: '#00454A', marginLeft: '5px'}} />
                  </Tooltip>
                </Typography>
                <p className="text-lg font-semibold text-gray-600 mb-5 font-libre">
                  {nearestAirport?.ShortName}, {nearestAirport?.city}, {nearestAirport?.country}
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  fullWidth
                  sx={{
                    backgroundColor: '#00454A',
                    '&:hover': {
                      backgroundColor: '#00454A',
                    },
                    textTransform: 'none',
                    color: 'white',
                    marginBottom: '10px'
                  }}
                  onClick={() => savePreferredAirport(nearestAirport)}
                >
                  Confirm and Save
                </Button>

                <Button
                    color="primary"
                    type="button"
                    fullWidth
                    onClick={() => setShowAutocomplete(true)}
                    sx={{ textTransform: 'none', color: '#020202', textDecoration: 'underline' }}>
                    Select another airport
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1" gutterBottom>
                  Please select your preferred airport
                  <Tooltip title="We will use this airport as your origin and try to show you more personalized flight deals" placement="right">
                    <InfoIcon sx={{color: '#00454A', marginLeft: '5px'}} />
                  </Tooltip>
                </Typography>
                <FlightSearchAutocomplete
                  label="Search and select airport"
                  showLabel={true}
                  icon={<FlightTakeoffIcon />}
                  name="selectedAirport"
                  setFieldValue={(_, value) => setSelectedAirport(value)}
                  patch={{ value: selectedAirport }}
                />
                <Box marginTop={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => savePreferredAirport(selectedAirport)}
                    disabled={!selectedAirport}
                    sx={{
                      backgroundColor: '#00454A',
                      '&:hover': {
                        backgroundColor: '#00454A',
                      },
                      textTransform: 'none',
                      color: 'white',
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Paper>
    </Popper>
  );
};

export default UserAirportDropdown;