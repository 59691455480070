import { createTheme, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import styles from './About.module.scss';
import { RatingBadge } from '../RatingBadge/RatingBadge';
import { useRouter } from 'next/router';
import { BCBreadcrumbs } from '../index';
import { useSelector } from 'react-redux';
import { CONTACT_INFO } from '../../config';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#0a0a0a',
  },
});
export const About = ({ setLoginDialogState }) => {
  const router = useRouter();
  useEffect(() => {
    if (router.asPath.includes('#')) {
      const section = router.asPath.split('#')[1];
      document.querySelector(`a[name=${section}]`).scrollIntoView();
    }
  }, [router]);

  const people = [
    {
      name: 'Jason Eckhoff',
      position: 'Founder & CEO',
      subtitle: 'Editor-In-Chief',
      image: 'assets/jason.jpg',
      info: `Jason is the Founder and CEO of BusinessClass.com, with over 20 years of experience in the digital travel space. He has played a key role in shaping online travel distribution, successfully building and managing multiple online travel agencies across Scandinavia.
      Jason is recognized as Entrepreneur of the Year by Ernst & Young after his company Reisegiganten became one of Norway’s fastest-growing travel businesses. Seeing an opportunity in the premium travel segment, he founded BusinessClass.com that was launched in 2021 - a platform dedicated to helping travelers find and experience the best in business and first-class flights, five-star hotels, and luxury travel insights.
      Also taking on the role as the Editor-in-Chief, Jason combines his industry expertise with his passion for premium travel, writing in-depth reviews of business and first-class products, as well as five-star hotels around the world. He enjoys exploring the finer details of luxury travel, from seat comfort to service quality, always with the goal of providing genuine, insightful recommendations to fellow travelers.
      With a strong background in travel and a commitment to high-quality journalism and expert insights, Jason continues to lead BusinessClass.com as a trusted resource for those seeking elevated travel experiences.`,
    },
    // {
    //   name: 'Henrik Hanevold',
    //   position: 'Chief Product Officer',
    //   image: 'assets/henrik.jpg',
    //   info: 'Henrik joined BusinessClass.com in early 2020, bringing more than 20 years of experience from the travel industry with him. He looks after product strategy, business development, supplier relations and partnerships. He spent the first decade of his career with Radisson Blu Hotels & Resorts before moving on to travel technology giant Amadeus. In 2013, he joined Singapore Airlines, managing leisure sales in the Nordics. Henrik has visited more than 60 countries and lived in Canada, Sweden and the UK before settling back in Norway.',
    // },
    {
      name: 'Thomas Vervik',
      position: 'Chief Technology Officer',
      image: 'assets/thomas.jfif',
      info: 'Thomas is a senior developer with long experience managing development teams and complex IT projects. He has worked with BusinessClass.com since 2018 and has a Masters of Science degree from the University of Bergen. He has experience from Singapore, the Czech Republic, Ukraine and Uganda, and has worked for companies such as TV2, Umoe, Nordea and Bipper Communications.',
    },
    {
      name: 'Lena Bjørkum',
      position: 'Co-Founder',
      image: 'assets/lena.jpg',
      info: 'Lena co-founded BusinessClass.com together with Jason back in 2015. She is a hotel aficionado and experienced traveller. Lena looks after concept development and quality assurance as well as hotel and destination research.',
    },
    {
      name: 'Adeel Khan ',
      position: 'SEO Lead',
      image: 'assets/Adeel.jpg',
      info: 'Adeel is a seasoned SEO specialist with years of experience working with a diverse range of industries including e-commerce, legal, medical and IT. Adeel joined BusinessClass.com in 2022. Adeel is responsible for driving organic growth and developing effective content strategies.',
    },
    {
      name: 'Amanda Ling',
      position: 'Content Manager',
      image: 'assets/Amanda.jpeg',
      info: 'Amanda is responsible for planning and publishing digital content across our website, social media, and newsletter. Before joining BusinessClass.com, she ran one of the largest marketing agencies in the South West England. With over 25 years’ experience, Amanda specialises in creating digital content that creates long lasting and valuable customer relationships.',
    },

    {
      name: 'Anton Kolychev',
      position: 'Head of Development, IT',
      image: 'assets/anton-kolychev.jpg',
      info: 'Anton is an experienced senior website developer. He oversees the front and back-end development at BusinessClass.com. Anton holds a Masters Degree in Electronics Systems and has extensive experience in IT security solutions. Before joining BusinessClass.com in early 2021, Anton worked for technology giant Samsung at its Research and Development Institute in Ukraine.',
    },
    // {
    //   name: 'Danil Serada',
    //   position: 'Senior Frontend Engineer',
    //   image: 'assets/danil-sereda.jpg',
    //   info: 'Danil is a seasoned senior front-end developer with many years experience in building progressive web applications. Over the years he has worked for a wide range of clients in the retail, insurance, personal safety and public sectors.',
    // },
    {
      name: 'Oleh Leskiv',
      position: 'Senior Frontend Engineer',
      image: 'assets/Oleh.JPG',
      info: 'With more than 10 years of experience in developing complex UI applications, Oleh has worked within big outsourcing companies with distributed teams and small start-ups - as a key developer and team leader. Oleh has experience in the healthcare, marketing, finance, insurance, entertainment sectors. He was involved in the creation of projects for clients including SAP, US Golf Association and DASH Financial Technologies. He has also created UI applications from scratch for both Norwegian and international start-up projects.',
    },
    {
      name: 'Tamim Md. Hossain',
      position: 'Deals Expert',
      image: 'assets/tamim.jpg',
      info: 'Tamim is a seasoned points and miles specialist with over six years of experience in award flight bookings. At BusinessClass, he helps clients secure the best-value flights, ensuring seamless and cost-effective travel. He also researches and lists the best market offers on the website. With deep expertise in airline alliances, redemption strategies, and premium cabin availability, Tamim tailors optimal flight solutions for every traveler.'
    },
    {
      name: 'Ursula Mahmoud',
      position: 'Customer Experience Manager',
      image: 'assets/ursula.jpg',
      info: `Ursula is a results-driven professional with over 10 years of experience in Customer Experience Management and Sales Leadership. As a Deals Manager, Ursula specializes in driving sales growth, optimizing processes, and enhancing customer engagement.
      With more than three years in the aviation industry, she has deep expertise in airline operations, fare management, and travel solutions. Previously, as a Team Leader at Trevolution Group, Ursula led high-performing sales teams in the aviation sector, helping customers secure the best travel deals. Prior to that, as a Q Manager at Microsoft, focused on quality management and process optimization.
      Passionate about innovation and teamwork, Ursula is committed to delivering exceptional results and elevating customer experiences.`
    },
    {
      name: 'Varun Sharma',
      position: 'Contributing Writer',
      image: 'assets/varun.jpg',
      info: 'With over 25 years of experience in luxury travel journalism, Varun has contributed to a lot of the content on BusinessClass.com. BBC-trained, Varun has appeared in print, on radio, television, and now online media - having worked for outlets including the BBC, Telegraph (UK), MSNBC, Travel Channel and Condé Nast Traveller. Varun has visited over a hundred countries, reviewed hundreds of luxury hotels & resorts and dozens of airlines, and cooked with some of the greatest chefs in the world - many of whom appeared on his international television series - Inside Luxury Travel.',
    },
    {
      name: 'James Moreton',
      position: 'Contributing Writer',
      image: 'assets/james-moreton.jpg',
      info: 'Jim, a former British CID Detective, turned crime fiction writer has a passion for travel and a keen eye for detail. Using the pseudonym of James D Mortain, Jim has authored five published thriller novels with a sixth on the way. When he`s not writing edge-of-the-seat fiction, Jim loves nothing more than spending quality time with his young family exploring new cultures and cuisines on faraway shores.',
    },
    {
      name: 'Ramsey Qubein',
      position: 'Contributing Writer',
      image: 'assets/ramsey.jpg',
      info: "Ramsey flies more than 450,000 miles per year and has traveled to 166 countries, all seven continents, and 50 U.S. states. As a travel journalist, he covers hotels, cruises, airlines, and loyalty programs from around the globe. His work has appeared in numerous publications including Conde Nast Traveler, Travel+Leisure, Forbes.com, BBC Worldwide, USA Today, Frommers.com, Business Traveler, Fortune, Airways, and AAA magazines. He is a member of the Society of American Travel Writers (SATW) and completed a Master's Degree thesis researching the evolution of brands in the airline industry.",
    },
    {
      name: 'Punita Malhotra',
      position: 'Contributing Writer',
      image: 'assets/punita.jpg',
      info: "Punita shifted gears from a career in entrepreneurship and publishing to live her dream of travel and writing. She is a passionate traveller, writer, blogger and content strategist, with expertise in travel, art, food, history, heritage and culture. She works on commissioned articles for select print and digital platforms, including Outlook Traveller, Matador Network, A Small World' and inflight magazines of Air Vistara, Indigo Airlines, Air India, Air Asia and Jazeera Airways. She partners with innovation-obsessed travel brands to craft revitalizing, engaging, inspiring and stand-out content. She 'stays travellous' by penning her personal wanderlust stories through her blog, 100cobbledroads.",
    },
    {
      name: 'Dawn Jorgensen',
      position: 'Contributing Writer',
      image: 'assets/dawn.jpg',
      info: "A self-proclaimed earth advocate and beauty seeker, Dawn is a conscious traveller with a deep love for Africa, its people and the environment. Her career has embraced all aspects of travel, including running her own bespoke tour operating company, establishing an award-winning blog – The Incidental Tourist, and sharing stories from her international and local travels as a prolific freelance travel writer. Dawn's passion lies in sustainable and ethical travel practices and in communicating with travellers seeking out exceptional experiences in the places she knows – and loves. When not on the road, you'll find her in Cape Town beach walking her dogs, doing yoga, or figuring out how best to save a species.",
    },
    {
      name: 'Sugato Mukherjee',
      position: 'Contributing Writer',
      image: 'assets/sugato-mukherjee.jpg',
      info: 'Sugato is a travel journalist with bylines in The Globe and Mail, National Geographic Traveller, Fodor\'s Travel, Travel+Leisure, Atlas Obscura, Hidden Compass, Discovery, Silkwinds, Roads & Kingdoms, Mint Lounge and a host of inflight magazines. His coffee table book "An Antique Land: A Visual Memoir of Ladakh" has been widely acclaimed. Sugato writes, and also photographs his personal and professional projects; and his work on sulphur miners of East Java has been awarded by UNESCO. He collaborates with premium travel brands and tourism boards to highlight initiatives that blend luxury, elegance and sustainability through his creative content. Some of his works can be found at sugatomukherjee.com',
    },
    {
      name: 'Eivind Roald',
      position: 'Advisor & Lead Investor',
      image: 'assets/eivind.jpg',
      info: 'Eivind is an experienced leader within technology and travel. Having served as Executive Vice President & Chief Commercial Officer at SAS Scandinavian Airlines, he has also managed SAS EuroBonus, the largest loyalty program in the Nordics. Eivind was the first external investor in BusinessClass.com in 2018, bringing with him a wealth of airline insight and a deep understanding of the premium travel segment. His passion for people and the use of technology and digital solutions was born during his time at Hewlett-Packard and Accenture, and reinforced when he moved into the airline industry. Eivind is now CEO at QNTM Group, owned by Altor, one of the largest private equity investors in Europe. Eivind remains an active investor and advisor at BusinessClass.com.',
    },
    {
      name: 'Ingeborg Eckhoff',
      position: 'Production Associate',
      image: 'assets/ingeborg-eckhoff.jpg',
      info: 'Ingeborg is a member of the editorial team at BusinessClass.com. She publishes our editorial content onto the website and helps create the weekly newsletter. Ingeborg also updates the content that appears in our hotel search facility. She also assists with the sourcing and editing of images for editorials and builds and tests website links.',
    },
    {
      name: 'Henry Eckhoff',
      position: 'Production Associate',
      image: 'assets/henry-eckhoff.png',
      info: 'Henry supports the editorial and product teams at BusinessClass.com. He publishes editorial content and hotel listings. Henry also assists with the sourcing and editing of images for editorials and builds and tests website links.',
    },
    {
      name: 'Karl-Philip Lund',
      position: 'Board member',
      image: 'assets/karl.jpg',
      info: 'Karl-Philip was the first digital marketing consultant at the airline Norwegian and is an expert in fields such as internet strategy, email marketing, Google Adwords / search engine marketing, search engine optimization, organizational development, coaching, sales, project management and consulting.',
    },
    {
      name: 'Krister Nekstad',
      position: 'Board member',
      image: 'assets/Krister.jpg',
      info: 'Krister is an investor and board member of BusinessClass.com, bringing a wealth of experience in marketing and business development to our company.',
    },
  ];
  const [peopleState, setPeopleState] = useState(people);

  function expandInfo(p) {
    p.expanded = !p.expanded;
    setPeopleState([...peopleState]);
  }

  const userStore = useSelector((state) => state.user.value);

  function getContactPhoneNumber() {
    if (userStore) {
      return CONTACT_INFO[userStore.country] ? (
        <a href={`tel:${CONTACT_INFO[userStore.country]}`}>
          {CONTACT_INFO[userStore.country]}
        </a>
      ) : (
        <a href={`tel:+44 203 455 03 90`}>+44 203 455 03 90</a>
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.top}>
        <Image
          className={styles.topImage}
          src={`${process.env.mediaUrl}assets/cookie_front.jpeg`}
          layout="fill"
          objectFit={'cover'}
          objectPosition={'center 70%'}
        />
      </div>
      <div className={cn('container', 'font-libre')}>
        <BCBreadcrumbs headerText="About" />
        <div className={styles.content}>
          <div id="about" className="menu-marg-fix">
            <div className="row section vision content">
              <div className="column large-16 large-centered">
                <h1 className={cn(styles.welcome, 'font-fragment')}>
                  Welcome to BusinessClass.com
                </h1>
                <p>
                  BusinessClass.com offers the best premium travel search engine
                  in the world. The site is aimed at those who fly in First
                  Class, Business Class and Premium Economy cabins and stay in
                  the finest hotels &amp; resorts. BusinessClass.com searches
                  hundreds of travel sites to help you find the best premium
                  travel offers for both flights and accommodation.
                </p>
                <h2 className="font-fragment w-full">
                  A message from our Founder &amp; CEO
                </h2>

                <p className="message">
                  &quot;I had been working in travel tech for over 20 years, and
                  still found it hard to find the best travel offers. I was also
                  finding it difficult to understand the differences between all
                  the airline and hotel suggestions that were being provided by
                  the plethora of search engines. It was truly a jungle out
                  there. I concluded that no travel search engine catered for
                  the more discerning traveller looking for comfort and class at
                  a great price.
                </p>
                <p className="message">
                  In 2015, I came up with the concept of BusinessClass.com
                </p>
                <p className="message">
                  Using proprietary algorithms, we have meticulously designed
                  and built a search engine that not only collates the most
                  competitive flight and hotel prices but is also extremely
                  user-friendly.
                </p>
                <p className="message">
                  Our contribution to a greener tomorrow and sustainability in
                  travel is of utmost importance to me. To this end, we
                  highlight the green initiatives that are being implemented by
                  all the airlines and hotels &amp; resorts we review.
                </p>
                <p className="message">
                  There is much more to BusinessClass.com than just amazing
                  offers!&quot;
                </p>
                <p>Jason Eckhoff</p>
              </div>
            </div>

            <div className="row">
              <div className="column large-20 large-centered headers-index">
                <a href="#team">Team</a>
                <a href="#philosophy">Philosophy</a>
                <a href="#technology">Technology</a>
                <a href="#exclusive-offers">Exclusive Offers</a>
                <a href="#inspiration">Inspiration</a>
                <a href="#coming-soon">Coming Soon</a>
                <a href="#people">People</a>
                <a href="#rating">Rating</a>
                <a href="#partners">Partners</a>
                <a href="#invest">Invest</a>
                <a href="#contact">Contact</a>
              </div>
            </div>
            <div className="row mt-10">
              <div className="column large-18 large-centered">
                <img
                  src={process.env.mediaUrl + 'assets/3_culture.jpg'}
                  className="resize169"
                  alt=""
                />
              </div>
            </div>
            <div className="row section">
              <div className="column large-16 large-centered content ">
                <h3>
                  Team<a name="team"></a>
                </h3>
                <p>
                  Our dedicated and passionate team has extensive and successful
                  backgrounds in travel, technology, commerce, and journalism.
                </p>
                <p>
                  The team is here to help you figure out where to go and how to
                  get there, in the most cost-effective and timely manner.
                </p>
                <p>
                  For more on the team, please scroll further down on this page.
                </p>
                <h3>
                  Philosophy<a name="philosophy"></a>
                </h3>
                <p>
                  BusinessClass.com combines the latest technology, smart
                  innovation, and the values &amp; expert knowledge of a
                  traditional travel agent to help you identify your perfect
                  leisure and business trip.
                </p>
                <p>
                  We believe that however you choose to travel, your journey
                  should start from the moment you begin planning it. Although
                  BusinessClass.com helps you compare prices as well as quality,
                  we take it much further than that. We offer inspiration,
                  insight, recommendations, and useful information throughout
                  our site. We believe that you should be able to find all the
                  information you need, right here.
                </p>
                <p>
                  We are completely independent and offer full transparency. As
                  such, we do not accept payment by any third parties to rank
                  one option above another.
                </p>
                <h3>
                  Technology<a name="technology"></a>
                </h3>
                <p>
                  Our website searches hundreds of thousands of flights and
                  hotels. But unlike other search engines BusinessClass.com
                  deploys its own unique, innovative algorithms that selects and
                  highlights only the choices that matter to you, based on your
                  personal preferences and with added recommendations from us.
                </p>
                <h3>
                  Exclusive offers<a name="exclusive-offers"></a>
                </h3>
                <p>
                  Premium travellers are telling us all the time how hard they
                  find it to identify great offers. Identifying and delivering
                  you the best collection of global travel offers is in our DNA.
                  There are always offers available and we collect them in one
                  single place - here at BusinessClass.com.
                </p>
                <h3>
                  Inspiration<a name="inspiration"></a>
                </h3>
                <p>
                  Our own expert team of international writers and reviewers are
                  circumnavigating the globe writing honest reviews about
                  premium airline cabins and luxury hotels &amp; resorts.
                </p>
                <p>
                  All these reviews and original destination guides are
                  accessible – free - on BusinessClass.com. Every week we
                  publish a newsletter with inspiring articles and exclusive
                  offers to popular and indeed far-flung destinations. To
                  receive our complimentary newsletter, please{' '}
                  <a
                    className={'cursor-pointer'}
                    onClick={() =>
                      setLoginDialogState({ state: 'signUp', opened: true, sourcePage: 'About' })
                    }
                  >
                    sign up here
                  </a>
                  .
                </p>
                <h3>
                  Coming Soon<a name="coming-soon"></a>
                </h3>
                <p>
                  Our website development team never stops. We are constantly
                  adding new features – aimed at helping you - the discerning
                  traveller. If you have an idea, suggestion, or comment about
                  our website, we would love to hear from you.
                </p>
                <p>
                  Please <a href="#contact">contact us here</a>.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="column large-18 large-centered">
                <img
                  src={process.env.mediaUrl + 'assets/2_culture.jpg'}
                  className="resize169"
                  alt=""
                />
              </div>
            </div>
            <div className="row section content">
              <div className="column large-16 large-centered">
                <h2 className={'font-fragment'}>
                  People<a name="people"></a>
                </h2>
                <p>
                  Travel represents the epitome of globalisation and we believe
                  diversity enhances creativity. Our global team of experts
                  brings extensive wisdom in the fields of travel, technology,
                  commerce, and journalism. Our European headquarters is in
                  Oslo, Norway but we have colleagues based in Europe, Asia,
                  Africa and North America. Between us, we have explored over
                  170 countries around the world, have stayed in more than 2,500
                  luxury hotels and flown by over 100 carriers.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column large-22 large-centered people">
                {peopleState.map((p) => (
                  <div key={p.name} className="column large-8 people-info">
                    <div className="column small-12 small-centered expert-image">
                      <img
                        width="100%"
                        src={process.env.mediaUrl + p.image}
                        alt={p.name}
                        className={styles.avatar}
                      />
                    </div>
                    <div className="column large-12">
                      <div className="name">{p.name}</div>
                      <div className="position">{p.position}</div>
                      {p.subtitle && <div className="position">{p.subtitle}</div>}
                      <a className="about-people" onClick={() => expandInfo(p)}>
                        About {p.name.split(' ')[0]}
                      </a>
                    </div>
                    {p.expanded && (
                      <div
                        className={cn(
                          'column small-24 about-people-description'
                        )}
                      >
                        {p.info}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="row section rating">
              <div className="column large-16 large-centered">
                <h2 className={'font-fragment'}>
                  Rating<a name="rating"></a>
                </h2>
                <p>
                  Rating on <i>BusinessClass.com</i> is there to help you
                  understand what to expect from a hotel stay, a flight or
                  another travel related product or experience. User ratings,
                  Expert ratings – <i>BusinessClass tested</i> – as well as
                  proprietary algorithms – <i>BusinessClass Quality</i> – are
                  there to assist you in assessing quality.
                </p>
              </div>
              <div className="column large-24 large-centered">
                <h3 className={'font-fragment'}>Flights</h3>
                <div className="row flex small-column">
                  <div className="column large-12 content">
                    <div className="row flex">
                      <div className="column small-4 large-4 rating-badge">
                        <RatingBadge version={'badge-rated'} />
                      </div>
                      <div className="column small-20 large-20 rating-description">
                        <p className="title">BusinessClass Quality</p>
                        <p>
                          At <i>BusinessClass.com</i> we strive very hard to
                          offer a truthful understanding of the quality of a
                          given flight. It is hard for a human to understand and
                          assess the quality of millions of flight combinations
                          and all of their attributions. In comes{' '}
                          <i>BusinessClass Quality</i>, an algorithm-based score
                          that runs from 0 to 10. This score is calculated based
                          on a number of criteria. Our unique technology and
                          complex algorithms aims at simplifying and shortening
                          the lengthy and difficult process of identifying ideal
                          airline tickets. This is all the more important, as
                          buying a ticket in Premium Economy, Business Class or
                          First Class represents an investment in your comfort.
                          The <i>BusinessClass Quality</i> algorithms takes into
                          account a vast array of critical evaluation factors
                          and combines them into a single quality score, like:
                        </p>
                        <ul>
                          <li>
                            How good is the airline and cabin configuration.
                          </li>
                          <li>
                            How good is the seat. Can it convert to a fully
                            horizontal bed.
                          </li>
                          <li>
                            How good is the aircraft, noise and humidity levels,
                            affecting jet lag.
                          </li>
                          <li>Is there Wi-fi and other amenities.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column large-12 content">
                    <div className="row flex">
                      <div className="column small-4 large-4 rating-badge">
                        <RatingBadge version={'badge-tested'} />
                      </div>
                      <div className="column small-20 large-20 rating-description">
                        <p className="title">BusinessClass tested</p>
                        <p>
                          Editors at <i>BusinessClass.com</i> or approved expert
                          writers have flown all tested flights, and made a
                          thorough review of the quality of the flight,
                          evaluating many aspects of the experience. One general
                          rating is awarded on the overall flight. The report
                          will suggest who the experience is suited for and will
                          highlight three things we really enjoyed about the
                          flight. We will only publish reviews on flights we can
                          safely recommend and on flights awarded a rating of 8,
                          9 or 10 out of 10. Here is an overview on how we rate
                          flights:
                        </p>
                        <ol>
                          <li>
                            8/10: A good flight you will enjoy and do again
                          </li>
                          <li>
                            9/10: A great flight you will recommend to friends
                          </li>
                          <li>
                            10/10: A must-do experience on any travel lovers
                            bucket-list
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row info">
                  <div className="column large-12 content">
                    <div className="row">
                      <div className="column small-20 small-offset-4 rating-description user-ratings">
                        <p className="title">User ratings</p>
                        <p>
                          <i>BusinessClass.com</i> does not yet offer data on
                          user ratings on flights, this is in the pipeline.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column rating-section">
                <h3 className={'font-fragment'}>Hotels</h3>
                <div className="row flex small-column">
                  <div className="column large-12 content">
                    <div className="row flex">
                      <div className="column small-4 large-4 rating-badge">
                        <RatingBadge version={'badge-tested'} />
                      </div>
                      <div className="column small-20 large-20 rating-description">
                        <p className="title">BusinessClass Quality</p>
                        <p>
                          Editors at <a>BusinessClass.com</a> or approved expert
                          writers have stayed overnight at all tested
                          properties, and made a thorough review of the quality
                          of the stay, evaluating many aspects of the
                          experience. One general rating is awarded on the
                          overall stay and sub-ratings reflects how we
                          experienced Location, Rooms, Service, Style and Food.
                          The report will suggest who the property is suited for
                          and will highlight three thing we really enjoyed about
                          the stay. In general, BusinessClass tested, differ and
                          do not compare directly to user rating in how we rate
                          them. Further, we will publish reviews we can safely
                          recommend and on properties awarded a rating of 8, 9
                          or 10 out of 10. Here is an overview on how we rate
                          properties:
                        </p>
                        <ol>
                          <li>8/10: A good hotel you will enjoy and revisit</li>
                          <li>
                            9/10: A great hotel you will recommend to friends
                          </li>
                          <li>
                            10/10: A must-do experience on any hotel lovers
                            bucket-list
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="column large-12 content">
                    <div className="row">
                      <div className="column small-20 large-20 small-offset-4 large-offset-4 rating-description user-ratings">
                        <p className="title">User ratings</p>
                        <p>
                          The user ratings represents a good indication of the
                          overall satisfaction experienced by a large number of
                          hotel guests. We collect the user ratings from
                          reliable sources, from some of the worlds largest
                          whole sellers of hotel stays. For reliability, user
                          ratings are collected from verified guests.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="column large-18 large-centered">
                <img
                  src={process.env.mediaUrl + 'assets/1_nature.jpg'}
                  className="resize169"
                  alt=""
                />
              </div>
            </div>
            <div className="row section content">
              <div className="column large-16 large-centered">
                <h3 className={'font-fragment'}>
                  Partners<a name="partners"></a>
                </h3>

                <p>
                  Strategic partnerships and collaborations take centre stage at
                  BusinessClass.com. We work with a wide range of partners to
                  provide high-quality search results based on APIs, exclusive
                  offers for premium travel and inspirational, editorial
                  content. Travel providers, destination representatives and
                  suppliers dedicated to premium travel are welcome to get in
                  touch by sending an email to partners@businessclass.com.
                </p>
              </div>
              <div className="column large-16 large-centered">
                <h3 className={'font-fragment'}>
                  Invest in BusinessClass.com<a name="invest"></a>
                </h3>
                <p>
                  Since inception, BusinessClass.com has been on a creative and
                  cost-effective journey. Through prudent use of our seed
                  investment, we have built our website with original
                  proprietary algorithms and indeed software. Our current team
                  of experienced, professional investors are highly regarded and
                  hail from a wide range of sectors including e-commerce and
                  travel. If you share our vision and are interested in joining
                  us as we plan for exponential growth, please email us:
                  ir@businessclass.com
                </p>
              </div>
              <div className="column large-16 large-centered">
                <h3 className={'font-fragment'}>
                  Contact - Customer Service<a name="contact"></a>
                </h3>

                <p>
                  As a premium travel search engine, BusinessClass.com compares
                  prices from a range of airlines, hotel groups and travel
                  agents to help you find the best offers.
                </p>
                <p>
                  Please note that BusinessClass.com can only assist with
                  reservations made directly on BusinessClass.com. This will be
                  clearly indicated on your confirmation email. We do not charge
                  for Customer Service.
                </p>
                <p>
                  If you have booked directly with an airline or a hotel, or via
                  another travel agency, any and all inquiries regarding
                  reservations, changes and payments must be made directly to
                  them, using the contact details provided in their confirmation
                  email or on their websites. Charges may apply for customer
                  service provided by our Partners.
                </p>
                <p>
                  If your booking was made directly on BusinessClass.com, please
                  contact our Customer Service 24/7 on {getContactPhoneNumber()}
                </p>
                <p>
                  To contact BusinessClass.com, please send an email to &nbsp;
                  <a href="mailto:post@businessclass.com">
                    post@businessclass.com
                  </a>
                </p>
                <p>
                  Media requests for interviews or advertising may be sent to
                  &nbsp;
                  <a href="mailto:media@businessclass.com">
                    media@businessclass.com
                  </a>
                </p>
              </div>
              <div className="column large-16 large-centered">
                <h3 className={'font-fragment'}>
                  Contact - Corporate<a name="contact"></a>
                </h3>

                <p>For any inquiries that are not related to bookings:</p>
                <p>
                  Please email your requests to: &nbsp;
                  <a href="mailto:post@businessclass.com">
                    post@businessclass.com
                  </a>
                </p>
                <p>
                  Media requests for interviews or advertising, please email:
                  &nbsp;
                  <a href="mailto:media@businessclass.com">
                    media@businessclass.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
