import { createPopper } from '@popperjs/core';
import cn from 'classnames';
import { COUNTRIES } from '../../config';
import { changeCountry } from '../utils/changeCountry';
import styles from './CountrySelect.module.scss';
import arrowStyles from '../assets/Arrow.module.scss';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { memo, useCallback, useRef, useState } from 'react';

const CountryListItem = ({ country, session, closeDropdown }) => {
  const handleClick = useCallback(() => {
    changeCountry(country, session);
    closeDropdown();
  }, [country, session, closeDropdown]);

  return (
    <li className="relative">
      <div className="flex items-center h-8" onClick={handleClick}>
        <span
          className={cn(
            styles.flag32,
            `mr-1 flag-32 flag-${country.toLowerCase()}`
          )}
        />
        {COUNTRIES[country]}
      </div>
    </li>
  );
};
const MemoizedCountryListItem = memo(CountryListItem);

const CountrySelectComponent = ({
  selectedCountry,
  transparentHeader,
  session,
  ...props
}) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  // const dropdownHandlerRef = createRef();
  // const popoverDropdownRef = createRef();

  const dropdownHandlerRef = useRef(null);
  const popoverDropdownRef = useRef(null);
  // const openDropdownPopover = () => {
  //   createPopper(dropdownHandlerRef.current, popoverDropdownRef.current, {
  //     placement: 'bottom',
  //   });
  //   setDropdownPopoverShow(true);
  // };

  const openDropdownPopover = useCallback(() => {
    if (!dropdownPopoverShow) {
      createPopper(dropdownHandlerRef.current, popoverDropdownRef.current, {
        placement: 'bottom',
      });
      setDropdownPopoverShow(true);
    }
  }, [dropdownPopoverShow]);

  
  // const closeDropdownPopover = () => {
  //   setDropdownPopoverShow(false);
  // };

  const closeDropdownPopover = useCallback(() => {
    setDropdownPopoverShow(false);
  }, []);

  return (
    <div {...props} onMouseLeave={closeDropdownPopover}>
      <div
        className={cn('relative', 'flex items-center', 'w-full', 'font-libre')}
        ref={dropdownHandlerRef}
        onMouseEnter={openDropdownPopover}
      >
        <span
          className={cn(
            styles.flag32,
            `flag-32 flag-${selectedCountry?.toLowerCase()}`
          )}
        />
        <KeyboardArrowDownRoundedIcon
          className={cn({
            [arrowStyles.arrow]: transparentHeader,
            [arrowStyles.arrowBlackHeading]: !transparentHeader,
          })}
        />
      </div>

      <ul
        ref={popoverDropdownRef}
        className={cn(
          {
            block: dropdownPopoverShow,
            hidden: !dropdownPopoverShow,
          },
          'text-black',
          'float-left',
          'list-none',
          'text-left',
          'bg-white',
          'grid grid-cols-3',
          'px-3 py-2',
          styles.card
        )}
      >
        {Object.keys(COUNTRIES).map((country) => (
          // <li key={country} className="relative">
          //   <div
          //     className="flex items-center h-8"
          //     onClick={() => {
          //       changeCountry(country, session);
          //       closeDropdownPopover();
          //     }}
          //   >
          //     <span
          //       className={cn(
          //         styles.flag32,
          //         `flag-32 flag-${country.toLowerCase()}`
          //       )}
          //     />
          //     {COUNTRIES[country]}
          //   </div>
          // </li>

          <MemoizedCountryListItem
            key={country}
            country={country}
            session={session}
            closeDropdown={closeDropdownPopover} 
          />
        ))}
      </ul>
    </div>
  );
};

export const CountrySelect = memo(CountrySelectComponent);
