import { useEffect, useRef } from 'react';

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    // Check if the script is already loaded
    if (document.querySelector(`script[src="${src}"]`)) {
      return resolve();
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });

const GoogleOneTapAuth = ({ handleCredentialResponse }) => {
  const promptDisplayed = useRef(false);
  const scriptLoaded = useRef(false);
  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';
    const id = process.env.googleAppId;

    if (scriptLoaded.current) {
      return;
    }

    scriptLoaded.current = true;

    loadScript(src).then(() => {
      if (!window.google || !google.accounts) return;
      /*global google*/
      google.accounts.id.initialize({
        client_id: id,
        callback: handleCredentialResponse,
        // auto_select: true, // Optional: if you want to automatically sign in returning users
        cancel_on_tap_outside: false // Prevent dismissal when clicking outside the prompt
      });

      // Ensure prompt is displayed only once
      if (!promptDisplayed.current) {
        // setTimeout(() => {
            google.accounts.id.prompt(); // Trigger the One Tap UI
            promptDisplayed.current = true; // Prevent multiple prompts
        // }, 2000);
      }

      // Define the rePromptGoogleOneTap function
      GoogleOneTapAuth.rePromptGoogleOneTap = () => {
        google.accounts.id.cancel(); // Cancel any existing prompt
        google.accounts.id.prompt(); // Show the prompt again
      };
      
    }).catch(console.error);

    return () => {
      // const scriptTag = document.querySelector(`script[src="${src}"]`);
      // if (scriptTag) document.body.removeChild(scriptTag);
      // if (window.google && google.accounts) {
      //   google.accounts.id.cancel(); // Cancel any outstanding credential requests
      // }
      GoogleOneTapAuth.rePromptGoogleOneTap = null;
    };
  }, [handleCredentialResponse]);

  return null;
};

export default GoogleOneTapAuth;