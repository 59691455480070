import { useSelector } from 'react-redux';
import styles from './OffersListSection.module.scss';
import { httpGet } from '../../utils/httpClient';
import { useEffect, useState } from 'react';
import { OfferCard } from '../OfferCard/OfferCard';
import cn from 'classnames';
import { useRouter } from 'next/router';
import qs from 'qs';
import { Alert, createTheme, MenuItem, Select, Snackbar, ThemeProvider, ToggleButton, ToggleButtonGroup } from '@material-ui/core';
import { useWindowWidth } from '@react-hook/window-size';

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#00454A',
    },
    dark: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          margin: "4px",
          borderRadius: "10px",
          "&:not(:last-of-type)": {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          },
          "&:not(:first-of-type)": {
            marginLeft: '4px',
            borderLeft: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: '#20202033',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10
          },
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#BB9269',
            borderColor: '#BB9269',
            '&:hover': {
              backgroundColor: '#BB9269',
            },
          },
        },
      },
    },
  },
});

const getOfferItems = async (
  country,
  origin = '',
  destination = '',
  airline = '',
  cabinClass
) => {
  const params = [];

  if (country) {
    params.push(`country=${country}`);
  }

  if (origin) {
    params.push(`origin=${origin}`);
  }

  if (destination) {
    params.push(`destination=${destination}`);
  }

  if (airline) {
    params.push(`airline=${airline}`);
  }

  if (cabinClass) {
    params.push(`cabinClass=${cabinClass}`);
  }

  const urlParams = params.join('&');

  return await (
    await httpGet(`/api/offer/items?${urlParams}`)
  ).json();
};

export function OffersListSection({
  offers,
  type = 'card',
  showOrigin = false,
  country,
  origin,
  destination,
  airline,
  cabinClass,
  searchSource,
  customHeader
}) {
  const userStore = useSelector((state) => state.user.value);
  const [offerItems, setOfferItems] = useState([]);
  const [openedOfferId, setOpenedOfferId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    type: 'success',
  });
  const [regions, setRegions] = useState([]);
  const [activeRegion, setActiveRegion] = useState('All');
  const router = useRouter();
  const width = useWindowWidth();

  useEffect(() => {
    if (offers && offers.length > 0) {
      setOfferItems(offers);
      const regionsSet = new Set();
      offers.forEach((offer) => {
        const { region } = offer.destination;
        regionsSet.add(region ? region : 'Not specified');
      });
      
      const regionsArray = Array.from(regionsSet).map(region => ({ name: region }));
      setRegions(regionsArray);

      if (regionsArray.length > 0) {
        setActiveRegion('All');
        sortByRegion('All');
      }
    } else {
      const fetchOfferItems = async () => {
        const offerItems = await getOfferItems(
          country || userStore?.country,
          origin ? origin : '',
          destination,
          airline ? airline : '',
          cabinClass
        );
        setOfferItems([...offerItems.content]);
      };
      fetchOfferItems();
    }
  }, [offers]);

  useEffect(() => {
    if (offerItems.length > 0) {
      const query = qs.parse(router.query);
      if (Object.keys(query).length && query.offerid) {
        const offer = offerItems.find((offer) => offer.id === +query.offerid);
        if (offer) {
          setOpenedOfferId(offer.id);
        } else {
          setOpenSnackbar({
            open: true,
            message: 'Offer which you are trying to open from the link is not found or not available anymore',
            type: 'error',
          });
        }
      }
    }
  }, [offerItems]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleRegionChange = (event, region) => {
    setActiveRegion(region);
    sortByRegion(region);
  };

  
  const handleSelectRegionChange = (event) => {
    const region = event.target.value;
    setActiveRegion(region);
    sortByRegion(region);
  };

  const sortByRegion = (region) => {
    const regionToSort = region === 'Not specified' ? null : region;

    const filteredOffers = regionToSort === 'All'
      ? offers
      : offers.filter((offer) => {
          const offerRegion = offer.destination.region || null;
          return regionToSort === offerRegion;
        });
    setOfferItems(filteredOffers);
  };

  return (
    <>
      {customHeader && offerItems.length > 0 && customHeader}
      
      {regions && regions.length > 2 &&
        (!destination || destination === 'All destinations' || destination === '') && (
        <ThemeProvider theme={theme}>
          <div className="container flex justify-center px-3">
            <div className={cn(styles.regionFilters, 'w-full')}>
              <div className="flex flex-col lg:flex-row items-center w-full justify-center">
                {width < 1024 ? (
                  <div className='w-full flex'>
                    <Select
                      value={activeRegion}
                      onChange={handleSelectRegionChange}
                      fullWidth
                      className='bg-white font-bold'
                      variant="outlined"
                      sx={{
                        color: '#202020',
                        '& .MuiOutlinedInput-input': {
                          padding: '8px',
                          border: '2px solid transparent',
                          borderColor: activeRegion !== 'All' ? '#BB9269' : 'currentColor'
                        }
                      }}
                    >
                      <MenuItem value="All">All regions</MenuItem>
                      {regions.map((region) => {
                        return (
                          <MenuItem
                            key={region.name}
                            value={region.name}
                          >
                            {region.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                ) : (
                  <ToggleButtonGroup
                    orientation={width < 1024 ? 'vertical' : 'horizontal'}
                    size="large"
                    value={activeRegion}
                    fullWidth={width < 1024}
                    exclusive
                    onChange={handleRegionChange}
                  >
                    <ToggleButton
                      value={'All'}
                      aria-label={'All regions'}
                      className={styles.regionButton}
                      selected={activeRegion === 'All'}
                    >
                      <div
                        className="flex flex-col lg:flex-row items-center"
                      >
                        All regions
                      </div>
                    </ToggleButton>
                    {regions.map((region) => {
                      if (region.name === 'Not specified' && !userStore.is_admin) return null;
                      return (
                       
                        <ToggleButton
                          value={region.name}
                          aria-label={region.name}
                          key={region.name}
                          className={cn(styles.regionButton, region.name === 'Not specified' && 'bg-red-100')}
                          selected={region.name === activeRegion}
                        >
                          <div
                            className="flex flex-col lg:flex-row items-center"
                          >
                            {region.name}
                          </div>
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                )}
              </div>
            </div>
          </div>
        </ThemeProvider>
      )}
      <div
        className={cn(
          styles.offers,
          'mt-7',
          type === 'card' && 'grid lg:grid-cols-3 gap-6 grid-cols-1 mb-8'
        )}
      >
        {offerItems &&
          offerItems.length > 0 &&
          offerItems.map((offer, index) => (
            <OfferCard
              key={index}
              data={offer}
              searchSource={searchSource}
              isOpened={offer.id === openedOfferId}
              type={type}
              showOrigin={showOrigin}
            />
          ))}

        {/* {!offerItems.length && !loading && (
          <div className={styles.noResults}>No offers found</div>
        )} */}
      </div>

      <Snackbar
        open={openSnackbar.open}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={openSnackbar.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
