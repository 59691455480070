import cn from 'classnames';
import parse from 'html-react-parser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { createRef, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { setImageUrl } from '../../utils/setImageUrl';
import styles from './OfferPage.module.scss';
import dynamic from 'next/dynamic';
import { BCBreadcrumbs, SearchBox } from '../index';
import { ReviewItem } from '../Lists/ReviewItem';
import { getTextEditorParseOptions } from '../utils/textEditorParseOptions';
import { OffersListSection } from '../OffersListSection/OffersListSection';
import Button from '@material-ui/core/Button';
import { createTheme, IconButton, Select, ThemeProvider, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import { CABIN_CLASSES } from '../../utils/cabinClasses';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Link from 'next/link';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SortIcon from '@mui/icons-material/Sort';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import TuneIcon from '@mui/icons-material/Tune';
import AirlinesIcon from '@mui/icons-material/Airlines';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import BedIcon from '@mui/icons-material/Bed';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import ScrollableContent from '../ScrollableContent/ScrollableContent';
import { useWindowWidth } from '@react-hook/window-size';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import Image from 'next/image';


const CallToSignUp = dynamic(() => import('../CallToSignUp/CallToSignUp'));
const RecommendationsList = dynamic(() =>
  import('../RecommendationsList/RecommendationsList')
);

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#00454A',
    },
    dark: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});

export function OfferPage({
  article,
  recommendation,
  authCompleted,
}) {
  const defaultFilters = {
    origin: 'All',
    destination: 'All',
    class: 'Any class',
    sort: null
  };
  const userStore = useSelector((state) => state.user.value);
  const router = useRouter();
  const [mediaHeight, setMediaHeight] = useState(0);
  const [mediaWidth, setMediaWidth] = useState(0);
  const imageRef = createRef();
  const [countryOffers, setCountryOffers] = useState([...article.offers]);
  const [filters, setFilters] = useState(defaultFilters);
  const [cabinClasses, setCabinClasses] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const width = useWindowWidth();
  const isAirlineLanding = !!article.airline?.name;
 
  useEffect(() => {
    if (imageRef?.current) {
      setMediaHeight(imageRef.current.offsetWidth / 1.78);
      setMediaWidth(imageRef.current.offsetWidth);
    }
  }, [imageRef]);

  const options = getTextEditorParseOptions({article, mediaWidth, mediaHeight, imageRef, styles});
  const root = parse(article.content, options);

  useEffect(() => {
    const fetchUserCountry = async () => {
      const country = await userStore?.country;
      if (!country) return;

      // console.log('article offers for ', country.toUpperCase(), article.offers.filter((offer) => offer.country.toUpperCase() === country.toUpperCase()));
      
      if (article.offers?.length > 0) {
        const filteredOffers = article.offers.filter((offer) => offer.country.toUpperCase() === country.toUpperCase());
        setCountryOffers(filteredOffers);

        const uniqueOrigins = new Map();
        const uniqueDestinationCodes = new Set();
        const destinations = [];

        filteredOffers.forEach((offer) => {
          if (!uniqueOrigins.has(offer.origin.code)) {
            uniqueOrigins.set(offer.origin.code, offer.origin);
          }

          if (!uniqueDestinationCodes.has(offer.destination.code)) {
            uniqueDestinationCodes.add(offer.destination.code);
            destinations.push(offer.destination);
          }
        });

        setOrigins(Array.from(uniqueOrigins.values()).sort((a, b) => a.name.localeCompare(b.name)));
        setDestinations(destinations.sort((a, b) => a.name.localeCompare(b.name)));
      }
    };

    fetchUserCountry();
  }, [article, userStore?.country]);

  useEffect(() => {
    const cabinClasses = [...CABIN_CLASSES].reverse();
    setCabinClasses(cabinClasses);
  }, []);

  useEffect(() => {
    if (!filters.resetFilters) {
      const filteredOffers = article.offers.filter((offer) => {
        return (
        
          (filters.origin === 'All' || offer.origin.code === filters.origin) &&
          (filters.destination === 'All' || offer.destination.code === filters.destination) &&
          (filters.class === 'Any class' || offer.cabinClass === filters.class)
        );
      });

      setCountryOffers([...filteredOffers]);
    }

    if (filters.resetFilters) {
      setCountryOffers([...article.offers.filter((offer) => offer.country.toUpperCase() === userStore.country.toUpperCase())]);
      setFilters(defaultFilters);
    }

    if (filters.sort?.price) {
      handlePriceSort(filters.sort.price);
    }

    if (filters.sort?.destination) {
      handleDestinationSort(filters.sort.destination);
    }

  
  }, [filters])

  const handlePriceSort = (sortType) => {
    const sortedOffers = [...countryOffers];
    if (sortType === 'asc') {
      sortedOffers.sort((a, b) => a.price - b.price);
    } else if (sortType === 'desc') {
      sortedOffers.sort((a, b) => b.price - a.price);
    } else {
      // TODO: Sord by order
      sortedOffers.sort((a, b) => {
        const dateA = Date.parse(a.expirationDate);
        const dateB = Date.parse(b.expirationDate);
        return dateB - dateA;
      });
    }
    setCountryOffers(sortedOffers);
  };

  const handleDestinationSort = (sortType) => {
    const sortedOffers = [...countryOffers];
    if (sortType === 'asc') {
      sortedOffers.sort((a, b) => a.destination.name.localeCompare(b.destination.name));
    } else if (sortType === 'desc') {
      sortedOffers.sort((a, b) => b.destination.name.localeCompare(a.destination.name));
    } else {
      sortedOffers.sort((a, b) => {
        const dateA = Date.parse(a.expirationDate);
        const dateB = Date.parse(b.expirationDate);
        return dateB - dateA;
      });
    }
    setCountryOffers(sortedOffers);
  }

  const getTitle = () => {
    if (isAirlineLanding) {
      return `${article.airline.name} Flights, Tickets, Offers & Deals`;
    } else {
      return article.title;
    }
  }

  const getAirlineInfoIcon = (property) => {
    switch (property) {
      case 'signUp': 
        return <CardMembershipIcon fontSize="large" sx={{ color: '#86694B' }} />;
      case 'filterSearch':
        return <TuneIcon fontSize="large" sx={{ color: '#86694B' }}/>;
      case 'flyFrom':
        return <LocalAirportIcon fontSize="large" sx={{ color: '#86694B' }} />;
      case 'stayLoyal':
        return <AirlinesIcon fontSize="large" sx={{ color: '#86694B' }} />;
      case 'flexDates':
        return <ScheduleIcon fontSize="large" sx={{ color: '#86694B' }} />;
      case 'catchFlight':
        return <DateRangeIcon fontSize="large" sx={{ color: '#86694B' }} />;
      case 'setPriceAlert':
        return <NotificationsActiveIcon fontSize="large" sx={{ color: '#86694B' }} />;

      case 'checkTickets':
        return <AirplaneTicketIcon fontSize="large" sx={{ color: '#86694B' }} />;
      case 'bookHotels':
        return <BedIcon fontSize="large" sx={{ color: '#86694B' }} />;
      case 'getInsured':
        return <SafetyCheckIcon fontSize="large" sx={{ color: '#86694B' }} />;
    }
  };

  const renderFilters = ({ setFieldValue, values }) => {
    return (
      <>
        <div className={cn(styles.filterSmaller)}>
          {origins && (
            <Select
              value={values.origin}
              onChange={(event) => {
                setFieldValue('origin', event.target.value);
              }}
              defaultValue={'All'}
              fullWidth
              name={'origin'}
            >
              <MenuItem value={'All'}>- Any origin -</MenuItem>
              {origins.map((origin) => (
                <MenuItem key={origin.code} value={origin.code}>
                  {origin.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        <div className={cn(styles.filterSmaller)}>
          {destinations && (
            <Select
              value={values.destination}
              onChange={(event) => {
                setFieldValue('destination', event.target.value);
              }}
              defaultValue={'All'}
              fullWidth
              name={'destination'}
            >
              <MenuItem value={'All'}>- Any destination -</MenuItem>
              {destinations.map((destination) => (
                <MenuItem key={destination.code} value={destination.code}>
                  {destination.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        <div className={cn(styles.filterSmaller)}>
          {cabinClasses && (
            <Select
              value={values.class}
              onChange={(event) => {
                setFieldValue('class', event.target.value);
              }}
              fullWidth
              defaultValue={'Any class'}
              name={'class'}
            >
              <MenuItem value={'Any class'}>- Any class -</MenuItem>
              {cabinClasses.map((cabin) => (
                <MenuItem key={cabin.value} value={cabin.value}>
                  {cabin.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Head>
        <title>{getTitle()} | BusinessClass.com</title>
        {article.contentMetaData?.seoHtml === 'true' && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <meta name="description" content={article.subtitle} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="businessclass.com" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={article.subtitle} />
        <link
          rel="canonical"
          href={`${process.env.apiUrl}${router.asPath.split('?')[0]}`}
        />
        <meta name="twitter:image" content={setImageUrl(article.image)} />
        <meta
          property="og:title"
          content={getTitle()}
        />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={setImageUrl(article.image)} />
        <meta property="og:description" content={article.subtitle} />
      </Head>
      <div className={cn(styles.article, 'font-libre')}>
        <div className={styles.topContainer}>
          <div className="container">
            <BCBreadcrumbs headerText={article.title} />
            {!isAirlineLanding && (
              <div className={styles.overline}>Fly Business Class for less!</div>
            )}

            <h1 className={cn(styles.title, 'font-fragment', isAirlineLanding && 'mt-6')}>
              {article.title}
            </h1>

            {!isAirlineLanding && (
              <h4 className="font-libre text-base">{article.subtitle}</h4>
            )}
            {/* {article.image && (
              <div className={styles.mainImage}>
                {article.image.length === 1 && (
                  <Image
                    src={setImageUrl(article.image[0]?.image)}
                    layout="fill"
                    objectFit="cover"
                    alt={article.image[0]?.altText}
                  />
                )}
              </div>
            )} */}

            {isAirlineLanding && (
              <div className={cn(styles.topSectionSearch, 'container mx-auto px-4')}>
              <SearchBox
                type={width && width >= 1024 ? 'mainSearch' : 'sideSearch'}
                activeTab={0}
                searchSource={'Flights landing page'}
              />
            </div>
            )}

          </div>
        </div>
        <div className="container">
          <div className="sm:w-full mx-auto sm:px-4 my-6">
            {article.offers?.length > 0 && (
              <div className={styles.offersFilters}>
                <h2>Find the Best Flight Offers – Any Destination, Any Class</h2>
                <ThemeProvider theme={theme}>
                  <Formik
                    enableReinitialize
                    initialValues={filters}
                    validate={(value) => setFilters({ ...value })}
                  >
                    {({ setFieldValue, values, resetForm }) => (
                      <Form>
                        <div className={styles.filtersContainer}>
                          {renderFilters({ setFieldValue, values })}
                          <div className={styles.sortButton}>
                            <Tooltip title="Sort by price">
                              <IconButton
                                color="inherit"
                                type="reset"
                                onClick={() => {
                                  if (filters.sort?.price === 'desc') {
                                    setFieldValue('sort', {
                                      price: 'asc',
                                    });
                                  }
                                  if (filters.sort?.price === 'asc') {
                                    setFieldValue('sort', {
                                      price: null,
                                    });
                                  }
                                  if (!filters.sort?.price) {
                                    setFieldValue('sort', {
                                      price: 'desc',
                                    });
                                  }
                                }}
                                aria-label="clear"
                                sx={{
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRadius: '50%',
                                  color: '#0A0A0A',
                                  width: '56px',
                                  height: '56px',
                                  marginRight: '1rem',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <SortIcon />
                                {filters.sort?.price === 'desc' && (
                                  <ArrowRightAltIcon
                                    sx={{
                                      position: 'absolute',
                                      right: '-2px',
                                      transform: 'rotate(90deg)',
                                    }}
                                  />
                                )}
                                {filters.sort?.price === 'asc' && (
                                  <ArrowRightAltIcon
                                    sx={{
                                      position: 'absolute',
                                      right: '-2px',
                                      transform: 'rotate(-90deg)',
                                    }}
                                  />
                                )}

                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Sort alphabetically by destination name">
                              <IconButton
                                color="inherit"
                                type="reset"
                                onClick={() => {
                                  if (filters.sort?.destination === 'desc') {
                                    setFieldValue('sort', {
                                      destination: 'asc',
                                    });
                                  }
                                  if (filters.sort?.destination === 'asc') {
                                    setFieldValue('sort', {
                                      destination: null,
                                    });
                                  }
                                  if (!filters.sort?.destination) {
                                    setFieldValue('sort', {
                                      destination: 'desc',
                                    });
                                  }
                                }}
                                aria-label="clear"
                                sx={{
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRadius: '50%',
                                  color: '#0A0A0A',
                                  width: '56px',
                                  height: '56px',
                                  marginRight: '1rem',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <SortByAlphaIcon />
                                {filters.sort?.destination === 'desc' && (
                                  <ArrowRightAltIcon
                                    sx={{
                                      position: 'absolute',
                                      right: '-2px',
                                      transform: 'rotate(90deg)',
                                    }}
                                  />
                                )}
                                {filters.sort?.destination === 'asc' && (
                                  <ArrowRightAltIcon
                                    sx={{
                                      position: 'absolute',
                                      right: '-2px',
                                      transform: 'rotate(-90deg)',
                                    }}
                                  />
                                )}

                              </IconButton>
                            </Tooltip>
                          </div>
                          {Object.keys(filters).some((key) => filters[key] !== defaultFilters[key]) && (
                            <Tooltip title="Reset filters">
                              <IconButton
                                color="inherit"
                                type="reset"
                                onClick={() => {
                                  resetForm({ values: defaultFilters });
                                  setFilters({ resetFilters: true });
                                }}
                                aria-label="clear"
                                sx={{
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRadius: '50%',
                                  color: '#0a0a0a',
                                  width: '56px',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </ThemeProvider>
             </div>
            )}

            {userStore?.country && countryOffers?.length > 0 && (
              <OffersListSection
                offers={countryOffers}
                searchSource="Offer"
                type="card">
              </OffersListSection>
            )}

            {!countryOffers.length && Object.keys(filters).some((key) => filters[key] !== defaultFilters[key]) && (
              <div className={styles.noOffers}>
                <h3 className='font-libre'>No offers available for your filters</h3>
                <p>Please change or reset you filters to view more offers</p>
              </div>
            )}

            {!article.offers?.length && (
              <div className={styles.noOffers}>
                <h3 className='font-libre'>No offers currently available</h3>
                <p>You could check other airlines offer pages or come back later</p>

                <Link href="/offers" className='font-libre'>Check all offer pages</Link>
              </div>
            )}

            {article.offers?.length > 0 && !countryOffers?.length && Object.keys(filters).every((key) => filters[key] === defaultFilters[key]) && (
              <div className={styles.noOffers}>
                <h3 className='font-libre'>No offers available for your country</h3>
                <p>Please select another country or view all offers</p>

                <Button
                  color="secondary"
                  variant="contained"
                  type='button'
                  sx={{
                    borderRadius: '4px',
                    marginLeft: '0.5rem',
                    color: '#ffffff',
                    minWidth: '140px',
                    backgroundColor: '#0a0a0a',
                    '&:hover': {
                      color: '#ffffff',
                      backgroundColor: '#0a0a0a'
                    },
                  }}
                  onClick={(evt) => {
                    setCountryOffers([...article.offers]);
                    setOrigins(
                      Array.from(new Set(article.offers.map((offer) => offer.origin.code))).map(
                        (code) => article.offers.find((offer) => offer.origin.code === code).origin
                      )
                    );
                    setDestinations(
                      Array.from(new Set(article.offers.map((offer) => offer.destination.code))).map(
                        (code) => article.offers.find((offer) => offer.destination.code === code).destination
                      )
                    );
                    evt.stopPropagation();
                  }}
                >
                  Show all available offers
                </Button>
              </div>
            )}

            <div className="content">{root}</div>
          </div>

          {article.airlineReviews?.length > 0 && (
            <div className="container my-8">
              <h2>Expert Airline Reviews – Fly in Comfort and Style</h2>
               <div className={cn(styles.reviewList, 'lg:grid lg:grid-cols-2 lg:gap-10')}>
                  {article.airlineReviews.map((review, i) => {
                    return (
                      <div key={i}
                        className={cn(((article.airlineReviews.length === 1) || (article.airlineReviews.length > 2 && i === article.airlineReviews.length - 1)) && styles.halfWidth, styles.review, 'mb-3')}>
                        <ReviewItem
                          type="Airline"
                          review={review}
                          url={`/airlines/reviews/${review.textId}`}
                        />
                      </div>
                    );
                  })}
                </div>
            </div>
          )}

          {article.flightInfo && (
            <div className={cn('container my-8', styles.flightInfo)}>
              <h2>{article.airline.name} Flight Information</h2>
              <script type="application/ld+json" dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "https://schema.org",
                "@type": "WebPage",
                "headline": `${article.airline.name} Flights, Tickets, Offers & Deals | BusinessClass.com`,
                "about": [
                  {
                    "@type": "Organization",
                    "name": `${article.airline.name}`,
                    "sameAs": `https://en.wikipedia.org/wiki/${article.airline.name}_(airline)`
                  },
                  {
                    "@type": "Thing",
                    "name": "Flights",
                    "sameAs": "https://en.wikipedia.org/wiki/Flight"
                  },
                  {
                    "@type": "Thing",
                    "name": "Tickets",
                    "sameAs": "https://en.wikipedia.org/wiki/Ticket_(admission)"
                  },
                  {
                    "@type": "Thing",
                    "name": "Deals",
                    "sameAs": "https://en.wikipedia.org/wiki/Financial_transaction"
                  },
                  {
                    "@type": "Thing",
                    "name": "Airline",
                    "sameAs": "https://en.wikipedia.org/wiki/Airline"
                  },
                  {
                    "@type": "Thing",
                    "name": "Business Class",
                    "sameAs": "https://en.wikipedia.org/wiki/Business_class"
                  },
                  {
                    "@type": "Thing",
                    "name": "Premium Economy",
                    "sameAs": "https://en.wikipedia.org/wiki/Premium_economy"
                  },
                  {
                    "@type": "Thing",
                    "name": "Discount",
                    "sameAs": "https://en.wikipedia.org/wiki/Discounts_and_allowances"
                  },
                  {
                    "@type": "Thing",
                    "name": "International Travel",
                    "sameAs": "https://en.wikipedia.org/wiki/Travel"
                  }
                ],
                "mentions": [
                  {
                    "@type": "Thing",
                    "name": "Compare",
                    "sameAs": "https://en.wikipedia.org/wiki/Comparison"
                  },
                  {
                    "@type": "Thing",
                    "name": "Price",
                    "sameAs": "https://en.wikipedia.org/wiki/Price"
                  }
                ],
                "mainEntity": [
                  {
                    "@type": "Airline",
                    "name": `${article.airline.name}`,
                    "iataCode": `${article.airline.code}`,
                    "sameAs": "https://en.wikipedia.org/wiki/Emirates_(airline)"
                  }
                ]
              })
            }} />
              <div className='grid grid-cols-2 lg:grid-cols-3 gap-4'>
                <div className={cn(styles.flightInfoItem)}>
                  <LocalOfferIcon fontSize='medium'/>
                  <span>Best flight deal</span>
                  <p>{article.flightInfo.bestFlightDeal}</p>
                </div>

                <div className={cn(styles.flightInfoItem)}>
                  <EventIcon fontSize='medium' />
                  <span>Cheapest month to fly</span>
                  <p>{article.flightInfo.cheapestMonth}</p>
                </div>

                <div className={cn(styles.flightInfoItem)}>
                  <PlaceIcon fontSize='medium' />
                  <span>Destinations</span>
                  <p>{article.flightInfo.destinations}</p>
                </div>

                <div className={cn(styles.flightInfoItem)}>
                  <LocalAirportIcon fontSize='medium' />
                  <span>Most popular airport</span>
                  <p>{article.flightInfo.mostPopularAirport}</p>
                </div>

                <div className={cn(styles.flightInfoItem)}>
                  <ConnectingAirportsIcon fontSize='medium' />
                  <span>Average flights per week</span>
                  <p>{article.flightInfo.flightsPerWeek}</p>
                </div>
              </div>

            </div>
          )}

          {article.airlineInfo && (
            <div className={cn('container my-8', styles.flightInfo)}>
              <h2>How to Find the Cheapest {article.airline.name} Flights</h2>
              <p className='w-full'>Smart ways to secure the best {article.airline.name} flight prices:</p>
              
              {Object.entries(article.airlineInfo).map(([key, value]) => (
                <div key={key}>
                  {key === 'bookHotels' && (
                    <h2 className='mt-8'>Enhance Your Travel Experience</h2>
                  )}
                  <div className='flex w-full'>
                    <div className='mr-4'>
                      {getAirlineInfoIcon(key)}
                    </div>
                    <div className='w-full'>
                      <h3 className='font-libre text-left text-xl w-full font-semibold'>{value.header}</h3>
                      <p className='w-full px-0' dangerouslySetInnerHTML={{ __html: value.content }}></p>
                    </div>
                  </div>
                </div>
              ))}
            </div> 
          )}

          {!!article.faq?.length && (
            <div className="container flex flex-col justify-center items-center sm:w-full mb-8">

              <script type="application/ld+json" dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": article.faq.map((faq) => (
                      {
                        "@type": "Question",
                        "name": faq.question,
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": [faq.answer]
                        }
                      }
                    ))
                  })
                }}>
              </script>

              <h2>
                Frequently Asked Questions
              </h2>
              {article.faq.map((faq) => (
                <ScrollableContent
                  key={faq.question}
                  heading={faq.question}
                  content={faq.answer}
                  style={styles.scrollableContent}
                />
              ))}
            </div>
          )}

          {article.otherAirlines && article.otherAirlines.length > 0 && (
            <div className="container flex flex-col justify-center items-center sm:w-full mb-4">
              <h2>Other Popular Airlines</h2>
              <div className="flex flex-wrap justify-center items-center bg-white p-4">
                {article.otherAirlines.map((item) => (
                  <Link
                    href={'/offers/' + item.textId}
                    key={item.code}
                  >
                    <a
                      className="flex flex-col justify-center items-center p-2 cursor-pointer"
                      aria-label={item.airline.name}
                      role="link"
                    >
                      <Image
                        src={process.env.mediaUrl + item.image.replace('/', '')}
                        alt={item.name}
                        width={30}
                        height={30}
                      />
                      <span>{item.airline.name}</span>
                    </a>
                  </Link>
                ))}
              </div>
            </div>
          )}
          
        </div>
      </div>
      {userStore && !userStore.userid && (
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="w-full mx-auto my-20 sm:mb-10">
              {inView && (
                <CallToSignUp
                  country={userStore?.country}
                  authCompleted={authCompleted}
                  sourcePage={'Article'}
                />
              )}
            </div>
          )}
        </InView>
      )}
      <div className="container flex justify-center items-center sm:w-full mt-16">
        <RecommendationsList items={recommendation} />
      </div>
    </>
  );
}
