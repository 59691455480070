import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Dialog } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { InView } from 'react-intersection-observer';
import { CountrySelect, DropDown } from '../../components';
import { CURRENCY } from '../../config';
import { MobileHeader } from '../MobileHeader/MobileHeader';
import styles from './Header.module.scss';
import logoStyles from '../../components/assets/Logo.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../public/logo.svg';
import cn from 'classnames';
import { navigationItems } from './navitems/NavigationItems';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../utils/store/features/userSlice';
import { changeCurrency } from '../../components/utils/changeCurrency';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import PersonIcon from '@mui/icons-material/Person';
import { HAS_ETG } from '../../config/hasETG';
import GoogleOneTapAuth from '../../components/Authentication/GoogleOneTapAuth/GoogleOneTapAuth';
import { httpGet, httpPost } from '../../utils/httpClient';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import UserAirportDropdown from './UserAirportDropdown/UserAirportDropdown';

const getProfile = async () => {
  try {
    const res = await httpGet(`/api/user/profile`);
    return await res.json();
  } catch (e) {
    console.log(e);
  }
  return null;
}

const Authentication = dynamic(() =>
  import('../../components/Authentication/Authentication')
);
export const Header = ({
  handleSession,
  transparentHeader,
  standalone = false,
  ...props
}) => {
  const [sessionState, setSessionState] = useState(null);
  const [sessionLoading, setSessionLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [mainPage, setMainPage] = useState(false);
  const [showForum, setShowForum] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [loginDialogState, setLoginDialogState] = useState(false);
  const userStore = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(0);
  const [hideHeader, setHideHeader] = useState(false);
  const width = useWindowWidth();
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [googlePromptLoaded, setGooglePromptLoaded] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const router = useRouter();
  const logoRef = useRef(null);
  useEffect(() => {
    if (!sessionState?.userid && !googlePromptLoaded) {
      setGooglePromptLoaded(true); // Ensure Google One Tap shows only once
    }
  }, [sessionState?.userid, googlePromptLoaded]);
  useEffect(() => {
    setWindowWidth(width);
  }, [width]);
  useEffect(() => {
    if (document.location.pathname === '/') {
      setMainPage(true);
    }
    let cookieState = JSON.parse(localStorage.getItem('bcCookieConsentShowed'));
    if (cookieState) {
      setCookieAccepted(cookieState);
    } else {
      window.addEventListener('storage', () => {
        cookieState = JSON.parse(localStorage.getItem('bcCookieConsentShowed'));
        if (cookieState) {
          setCookieAccepted(cookieState);
        }
      });
    }

    if (standalone) {
      session();
    }
    let lastScrollTop = 0;

    // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    window.addEventListener(
      'scroll',
      function () {
        // or window.addEventListener("scroll"....
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
          setHideHeader(true);
        } else if (st < lastScrollTop) {
          setHideHeader(false);
        } // else was horizontal scroll
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );
  }, []);
  useEffect(() => {
    if (userStore) {
      setSessionLoading(false);

      if (userStore.userid && !profileData) {
        getProfile().then((profile) => {
          console.log(profile);
          setProfileData(profile);
          if (profile?.userAirports?.length > 0) {
            setPopperOpen(false);
          } else {
            setPopperOpen(true);
          }
        });
      } else {
        try {
          const isAirportClosed = JSON.parse(localStorage.getItem('BC_preferredAirportClosed'));
          const preferredAirport = JSON.parse(localStorage.getItem('BC_preferredAirport'));
          if (!isAirportClosed && !preferredAirport) {
            setPopperOpen(true);
          }
        } catch (error) {
          console.error("Error accessing localStorage: ", error);
        }
      }
    }
    setSessionState(userStore);
    if (
      userStore?.country === 'SE' ||
      userStore?.country === 'DK' ||
      userStore?.country === 'NO'
    ) {
      setShowForum(true);
    } else {
      setShowForum(false);
    }
  }, [userStore, profileData]);

  const authCompleted = () => {
    console.log('Auth completed');
    setLoginDialogState(false);
    session();
  };
  const session = (isSNAuth) => {
    handleSession()
      .then((userInfo) => {
        setSessionLoading(false);
        const user = !userInfo?.country
          ? {
              userid: null,
              country: 'us',
              detectCountry: null,
              detectRegion: null,
              is_admin: null,
              currency: 'USD',
              username: null,
              fullname: null,
              membershipNumber: null,
            }
          : userInfo;
        if (user.country === 'uk') {
          user.country = 'gb';
        }
        if (isSNAuth) {
          user.isSNAuth = true;

          const tagManagerArgs = {
            dataLayer: {
              event: 'accountCreated',
              userCountry: user.country.toUpperCase(),
              pageType: router?.pathname,
              source: 'google',
              membershipId: user.membershipNumber,
              signupSource: 'Google One Tap',
            },
          };
          TagManager.dataLayer(tagManagerArgs);
        }
        dispatch(setUser(user));
        setSessionState(user);
      })
      .catch((err) => {
        console.log(err);
        if (sessionState) {
          setSessionLoading(false);
          dispatch(setUser({}));
          setSessionState(null);
        }
      });
  };

  const redirectToProfile = () => {
    if (!router.pathname.includes('flightsearch')) {
      window.location.href = '/profile?accountCreated=true';
    } else {
      window.open('/profile?accountCreated=true', '_blank');
    }
  };


  const handleClose = () => {
    setPopperOpen(false);
    localStorage.setItem('BC_preferredAirportClosed', JSON.stringify(true));
  };

  return (
    <>
      <header
        className={cn(
          {
            [styles.hidden]: hideHeader,
            [styles.transparentHeader]: transparentHeader,
            [styles.header]: !transparentHeader,
          },
          'font-libre',
          'items-center',
          'top-0',
          'w-full'
        )}
        {...props}
      >
        <div
          className={cn(
            styles.headerContentContainer,
            'lg:flex sm:hidden flex-row text-white px-2 lg:px-6 py-2 w-full'
          )}
        >
          <nav ref={logoRef}>
            <ul className="flex h-full">
              <li className="my-auto">
                <a href="/" aria-label="Go to main BusinessClass.com page">
                  <Logo
                    className={cn(logoStyles.logo, {
                      [logoStyles.logoBlack]: !transparentHeader,
                    })}
                  />
                </a>
              </li>
            </ul>
          </nav>

          <nav className="ml-auto flex flex-row font-sans text-white font-libre p-2">
            <ul className="flex menu">
              <li className={cn(styles.navItem)}>
                <SearchIcon
                  className={cn(styles.searchIcon, {
                    [styles.darkSearchIcon]: !transparentHeader,
                  })}
                  onClick={() => {
                    setSearchState(!searchState);
                  }}
                />
                <div
                  className={cn({
                    hidden: !searchState,
                    visible: searchState,
                    [styles.searchContainer]: searchState,
                  })}
                >
                  {cookieAccepted && (
                    <Script
                      src="https://cse.google.com/cse.js?cx=e8c19a0f685acc0d8"
                      strategy="lazyOnload"
                    />
                  )}
                  <div className="gcse-search"></div>
                </div>
              </li>
              {/*{!mainPage && (*/}
              {/*  <li className={cn(styles.navItem, 'px-3')}>*/}
              {/*    <a*/}
              {/*      href="/"*/}
              {/*      className={cn('px-3 hover:no-underline', {*/}
              {/*        'text-black': !transparentHeader,*/}
              {/*        'text-white': transparentHeader,*/}
              {/*        [styles.darkFont]: !transparentHeader,*/}
              {/*      })}*/}
              {/*    >*/}
              {/*      Search*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*)}*/}
              {/* {showDealsEngine && (
                <li className={cn(styles.navItem, 'px-3')}>
                  <div>
                    <a
                      href="/flightdeals"
                      className={cn('hover:no-underline text-bold', {
                        'text-black': !transparentHeader,
                        'text-white': transparentHeader,
                        [styles.darkFont]: !transparentHeader,
                      })}
                    >
                      FlightDeals
                    </a>
                  </div>
                </li>
              )} */}
              <li className={cn(styles.navItem, 'px-3')}>
                <div>
                  <a
                    href="/club"
                    className={cn('hover:no-underline text-bold', {
                      'text-black': !transparentHeader,
                      'text-white': transparentHeader,
                      [styles.darkFont]: !transparentHeader,
                    })}
                  >
                    The Club
                  </a>
                </div>
              </li>
              <li className={cn(styles.navItem, 'px-3')}>
                <DropDown
                  items={navigationItems.airlines}
                  transparentHeader={transparentHeader}
                  label="Airlines"
                />
              </li>
              <li className={cn(styles.navItem, 'px-3')}>
                <DropDown
                  items={navigationItems.flights}
                  transparentHeader={transparentHeader}
                  label="Flights"
                />
              </li>
              <li className={cn(styles.navItem, 'px-3')}>
                <DropDown
                  items={navigationItems.hotels}
                  transparentHeader={transparentHeader}
                  label="Hotels"
                />
              </li>
              <li className={cn(styles.navItem, 'px-3')}>
                <DropDown
                  items={navigationItems.more}
                  transparentHeader={transparentHeader}
                  isForumShown={showForum}
                  label="More"
                />
              </li>
              {/* {showForum && (
                <li className={cn(styles.navItem, 'px-3')}>
                  <div>
                    <a
                      href="/forum"
                      className={cn('px-3 hover:no-underline', {
                        'text-black': !transparentHeader,
                        'text-white': transparentHeader,
                        [styles.darkFont]: !transparentHeader,
                      })}
                    >
                      Forum
                    </a>
                  </div>
                </li>
              )} */}
              {/* <li className={cn(styles.navItem)}>
                <div>
                  <a
                    href="/about"
                    className={cn('px-3 hover:no-underline', {
                      'text-black': !transparentHeader,
                      'text-white': transparentHeader,
                      [styles.darkFont]: !transparentHeader,
                    })}
                  >
                    About
                  </a>
                </div>
              </li> */}
              <li className={cn(styles.navItem, 'px-3')}>
                <DropDown
                  items={Object.keys(CURRENCY).map((currency) => {
                    return {
                      label: currency,
                      action: () => changeCurrency(currency, session),
                      rel: 'nofollow',
                    };
                  })}
                  transparentHeader={transparentHeader}
                  label={sessionState?.currency}
                  columnsNumber={3}
                />
              </li>
              <li className={cn(styles.navItem, 'px-3')}>
                <CountrySelect
                  className={cn('flex items-center', {
                    'text-black': !transparentHeader,
                    'text-white': transparentHeader,
                  })}
                  selectedCountry={sessionState?.country}
                  transparentHeader={transparentHeader}
                  session={session}
                />
              </li>
              <li className={cn(styles.navItem, 'flex flex-col items-center')}>
                {!sessionState?.userid && (
                  <PersonOutlineRoundedIcon
                    className="cursor-pointer"
                    fontSize={'medium'}
                    sx={{
                      color: transparentHeader ? '#ffffff' : '#202020',
                    }}
                    onClick={() => {
                      setLoginDialogState({ state: 'signUp', opened: true, sourcePage: 'Header link' });
                    }}
                  />
                )}
                {sessionState?.userid && (
                  <PersonIcon
                    className="cursor-pointer"
                    fontSize={'medium'}
                    sx={{
                      color: transparentHeader ? '#ffffff' : '#202020',
                    }}
                    onClick={() => {
                      if (sessionState?.userid) {
                        document.location = '/profile';
                        return;
                      } else {
                        setLoginDialogState({ state: 'signUp', opened: true, sourcePage: 'Header link' });
                      }
                    }}
                  />
                )}

                {/* {sessionState?.userid && profileData &&profileData.avatar && (
                  <div>
                    <Image src={process.env.mediaUrl + profileData?.avatar.replace('/', '')} width={30} height={30}></Image>
                  </div>
                )} */}
                <div
                  className={'text-xs'}
                  style={{
                    color: transparentHeader ? '#ffffff' : '#202020',
                  }}
                  onClick={() => {
                    if (sessionState?.userid) {
                      document.location = '/profile';
                      return;
                    } else {
                      setLoginDialogState({ state: 'signUp', opened: true, sourcePage: 'Header link' });
                      return;
                    }
                  }}
                >
                  {!sessionState?.userid && 'Login'}
                  {sessionState?.userid && 'Profile'}
                </div>
              </li>
              <li className={cn(styles.navItem, 'px-3')}>
                {!sessionLoading && HAS_ETG[sessionState?.country]?.hasEtg && (
                  <button
                    onClick={() => {
                      if (sessionState.country) {
                        let country = sessionState.country.toLowerCase();
                        if (country === 'gb') {
                          country = 'uk';
                        }
                        window.open(
                          `https://${
                            HAS_ETG[sessionState.country].code
                              ? HAS_ETG[sessionState.country].code
                              : country
                          }.flights.businessclass.com/rf/order-login`,
                          '_blank'
                        );
                      }
                    }}
                    className={cn(styles.loginButton, 'text-white font-libre')}
                  >
                    My Bookings
                  </button>
                )}
              </li>
            </ul>
          </nav>
        </div>
        <div className="lg:hidden sm:flex">
          <MobileHeader
            transparentHeader={transparentHeader}
            sessionState={sessionState}
            menuItems={navigationItems}
            toggleLoginDialog={(isOpen) => {
              setLoginDialogState({state: 'signUp', opened: isOpen, sourcePage: 'Header link'});
            }}
            loginDialogState={loginDialogState}
            session={session}
          />
        </div>
      </header>
      <Dialog
        open={loginDialogState?.opened || false}
        onBackdropClick={() => {
          if (loginDialogState?.state === 'setAiport') {
            redirectToProfile();
          }
          setLoginDialogState(false)
        }}
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        fullScreen={windowWidth <= 1024}
        fullWidth={false}
        maxWidth={'md'}
      >
        <IconButton
          color="inherit"
          onClick={() => {
            if (loginDialogState?.state === 'setAiport') {
              redirectToProfile();
            }
            setLoginDialogState(false)
          }}
          aria-label="close"
          className={styles.closeDialog}
        >
          <CloseIcon />
        </IconButton>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref}>
              {inView && <Authentication authCompleted={authCompleted} sourcePage={'Header link'} />}
            </div>
          )}
        </InView>
      </Dialog>

      {userStore && !userStore.userid && googlePromptLoaded && (
        <GoogleOneTapAuth handleCredentialResponse={(res) => {
          console.log('GoogleOneTapAuth');
          const login = (values, provider) => {
            httpPost(`/api/login/${provider}`, values)
              .then((response) => {
                if (response.ok) {
                  response.json().then((res) => {
                    if (res.isNewUser) {
                      // dispatch(setUser({...userStore, isSNAuth: true}));
                      session(true);
                    } else {
                      authCompleted();
                    }
                  });
                  return;
                }
              })
              .catch(async (error) => {
                console.log((await error).msg || (await error).reason?.[0].msg);
              });
          };

          login({ token: res.credential }, 'google');
        }}></GoogleOneTapAuth>
      )}

      {popperOpen && (
        <UserAirportDropdown
          anchorEl={logoRef.current}
          onClose={handleClose}
        />
      )}
    </>
  );
};
